import { combineReducers } from "redux";
import code from "./code";
import gameList from "./game-list";
import selectedGame from "./selected-game";
import sbcStatus from "./sbc-status";
import playerList from "./player-list";
import gameScene from "./game-scene";

export default combineReducers({
  selectedGame,
  gameList,
  code,
  sbcStatus,
  playerList,
  gameScene,
});
