import { QRCode } from "react-qrcode-logo";
import { withTheme } from "@material-ui/core/styles";
import { Box, Typography, Paper, Badge, Theme } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadGames, requestCode } from "../store/actions/action-creators";
import ApplicationState from "../models/application-state";
import ErrorBox from "../components/error-box";
import { URI } from "../constants/config";

interface StartScreenProps {
  theme: Theme;
}

const getConfigDeviceUrl = (url: string) => {
  return url.replace("dd", "");
};

const getDomainFromUrl = (url: string) => {
  return new URL(getConfigDeviceUrl(url)).hostname.toUpperCase();
};

const StartScreen: React.FC<StartScreenProps> = ({ theme }) => {
  const dispatch = useDispatch();
  const code = useSelector((state: ApplicationState) => state.code);
  const sbcStatus = useSelector((state: ApplicationState) => state.sbcStatus);
  const gameScene = useSelector((state: ApplicationState) => state.gameScene);

  const [loading, setLoading] = useState(true);
  const [configDeviceConnectUrl, setConfigDeviceConnectUrl] = useState(getConfigDeviceUrl(URI));

  const isInGame = useMemo(() => gameScene === "in_game", [gameScene]);

  useEffect(() => {
    dispatch<any>(requestCode());
    dispatch<any>(loadGames()).then(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (sbcStatus === "online") {
      setConfigDeviceConnectUrl(`${getConfigDeviceUrl(URI)}/?accessCode=${code}`);
    } else {
      setConfigDeviceConnectUrl(getConfigDeviceUrl(URI));
    }
  }, [sbcStatus, code]);

  return (
    <>
      {!loading && (
        <Box
          width={1}
          height="100vh"
          style={{
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/background.jpg)",
            backgroundSize: "cover",
          }}
        >
          <div style={{ height: "100%", width: "100%", backgroundColor: "rgba(27, 28, 27, 0.6)", overflowY: "auto" }}>
            <Box textAlign="center" style={{ height: "36%" }}>
              <img src="scoliaLogo.png" alt="logo" style={{ width: "auto", height: "100%" }} />
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center">
              <Paper style={{ height: "auto", width: 600 }}>
                <Typography
                  variant="h4"
                  component="div"
                  align="center"
                  style={{ fontWeight: "bold", marginTop: 46, marginBottom: 28 }}
                  color="textSecondary"
                >
                  WANT TO PLAY?
                </Typography>

                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                  <Badge
                    style={{
                      backgroundColor: "#AAAAAA",
                      height: 32,
                      width: 32,
                      borderRadius: 16,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" style={{ fontWeight: "bold", color: "#1B1C1B" }}>
                      1
                    </Typography>
                  </Badge>

                  <Typography
                    component="div"
                    variant="h5"
                    align="center"
                    style={{ color: theme.palette.text.disabled, marginLeft: 12 }}
                  >
                    Visit:
                  </Typography>
                </Box>

                <Typography
                  component="div"
                  variant="h4"
                  align="center"
                  style={{ fontWeight: "bold", marginTop: 12, marginBottom: 24 }}
                  color="textPrimary"
                >
                  {getDomainFromUrl(URI)}
                </Typography>

                <Box textAlign="center" marginBottom="24px">
                  <QRCode value={configDeviceConnectUrl} />
                </Box>

                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                  <Badge
                    style={{
                      backgroundColor: "#AAAAAA",
                      height: 32,
                      width: 32,
                      borderRadius: 16,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" style={{ fontWeight: "bold", color: "#1B1C1B" }}>
                      2
                    </Typography>
                  </Badge>

                  <Typography
                    component="div"
                    variant="h5"
                    align="center"
                    style={{ color: theme.palette.text.disabled, marginLeft: 12 }}
                  >
                    Enter this code:
                  </Typography>
                </Box>

                {sbcStatus === "online" && !isInGame && (
                  <Typography
                    variant="h4"
                    component="div"
                    align="center"
                    style={{ fontWeight: "bold", marginTop: 12, marginBottom: 46 }}
                    color="textPrimary"
                  >
                    {code}
                  </Typography>
                )}

                {sbcStatus === "offline" && !isInGame && <ErrorBox message="The board is currently offline." />}

                {isInGame && (
                  <ErrorBox message="The board is currently in play. Please wait until the game finishes." />
                )}
              </Paper>
            </Box>
          </div>
        </Box>
      )}
    </>
  );
};

export default withTheme(StartScreen);
