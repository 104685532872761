import { Store } from "redux";
import { NavigateFunction } from "react-router-dom";
import GameService from "../services/game-service";
import {
  reset,
  selectGame,
  setCode,
  sbcStatusChanged,
  updatePlayerList,
  setGameScene,
} from "../store/actions/action-creators";
import { incomingMessageTypes, outgoingMessageTypes } from "../constants/message-types";
import GameConfiguration from "../models/game-configuration";
import SbcStatus from "../models/sbc-status";
import SbcRegistrationError from "../models/sbcRegistrationError";
import { SERIAL_NUMBER } from "../App";
import Player from "../models/player";
import { DisplayDeviceData } from "../models/display-device-data";
import ApplicationState from "../models/application-state";

const REFRESH_TOKEN = "refreshToken";

export class EventHandler {
  constructor(
    private store: Store<ApplicationState>,
    private navigate: NavigateFunction,
    private emit: (message: string, payload?: any) => void,
  ) {}

  public handle(event: string, payload?: any): void {
    switch (event) {
      case incomingMessageTypes.gameConfigurationChanged:
        this.onGameConfigurationChanged(payload);
        break;
      case incomingMessageTypes.unlink:
        this.onUnlink();
        break;
      case incomingMessageTypes.codeResponse:
        this.onCodeResponse(payload);
        break;
      case incomingMessageTypes.selectGameRequest:
        this.onSelectGameRequest(payload);
        break;
      case incomingMessageTypes.startGame:
        this.onStartGame();
        break;
      case incomingMessageTypes.abortGame:
        this.onAbortGame();
        break;
      case incomingMessageTypes.throw:
        GameService.throwDetected(payload);
        break;
      case incomingMessageTypes.takeoutStarted:
        GameService.takeoutStarted(payload);
        break;
      case incomingMessageTypes.takeoutFinished:
        GameService.takeoutFinished(payload);
        break;
      case incomingMessageTypes.loadGame:
        this.onLoadGame();
        break;
      case incomingMessageTypes.playerListChanged:
        this.onPlayerListChanged(payload);
        break;
      case incomingMessageTypes.sbcStatusChanged:
        this.onSbcStatusChanged(payload);
        break;
      case incomingMessageTypes.sbcRegistrationError:
        this.onSbcRegistrationError(payload);
        break;
      case incomingMessageTypes.reconnect:
        this.onReconnect();
        break;
      case incomingMessageTypes.linkResponse:
        this.onLinkResponse(payload);
        break;
      case incomingMessageTypes.connect:
        this.onConnect();
        break;
    }
  }

  private onGameConfigurationChanged(values: GameConfiguration): void {
    GameService.updateGameConfiguration(values);
  }

  private onUnlink(): void {
    sessionStorage.setItem(REFRESH_TOKEN, "");
    GameService.reset();
    this.store.dispatch<any>(reset());
    this.navigate(`/?serialNumber=${sessionStorage.getItem(SERIAL_NUMBER)}`);
  }

  private onCodeResponse({ code }: { code: string }): void {
    this.store.dispatch(setCode(code));
  }

  private onSelectGameRequest({ gameId }: { gameId: string }): void {
    this.store.dispatch<any>(selectGame(gameId));
  }

  private onStartGame(): void {
    this.navigate("/game");
    GameService.startGame();
  }

  private onLoadGame(): void {
    this.navigate("/load-game");
  }

  private onAbortGame(): void {
    GameService.reset();
    this.navigate("/game-list");
  }

  private onSbcStatusChanged(sbcStatus: SbcStatus): void {
    this.store.dispatch<any>(sbcStatusChanged(sbcStatus));
  }

  private onSbcRegistrationError(sbcRegistrationError: SbcRegistrationError): void {
    if (sbcRegistrationError.reason === "in_use") {
      this.store.dispatch<any>(sbcStatusChanged({ status: sbcRegistrationError.reason }));
    }
  }

  private onLinkResponse({
    success,
    linkToken,
    displayDeviceData,
  }: {
    success: boolean;
    linkToken: string;
    msg: string;
    displayDeviceData: DisplayDeviceData;
  }): void {
    if (!success) {
      this.onUnlink();
      return;
    }

    sessionStorage.setItem(REFRESH_TOKEN, linkToken ?? "");

    const { playerList, selectedGame, gameScene, inGameDisplayDeviceCount } = displayDeviceData || {
      playerList: [],
      selectedGame: null,
      gameScene: "start_screen",
      inGameDisplayDeviceCount: 1,
    };

    if (selectedGame) {
      this.store.dispatch<any>(selectGame(selectedGame.gameId));
    }

    this.onPlayerListChanged(playerList);

    this.store.dispatch<any>(setGameScene(gameScene));

    if (gameScene !== "in_game" || inGameDisplayDeviceCount === 0) {
      this.navigate("/game-list");
    }
  }

  private onReconnect(): void {
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN);

    if (refreshToken) {
      const code: string = refreshToken;
      this.emit(outgoingMessageTypes.linkRequestWithToken, { code });
    }
  }

  private requestCode(): void {
    this.store.dispatch(setCode(null));
    this.emit(outgoingMessageTypes.codeRequest);
  }

  private onPlayerListChanged(players: Player[]): void {
    this.store.dispatch<any>(updatePlayerList(players));
    GameService.updatePlayerList(players);
  }

  private onConnect(): void {
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN);

    if (refreshToken) {
      this.onReconnect();
    } else {
      this.requestCode();
    }
  }
}
